import React from "react";
import LogoImg from "../assets/logo.png";
import PencilIpadImg from "../assets/pencil-ipad.png";
import ContentBox from "../components/ContentBox";
import StoreBtns from "../components/StoreBtns";

const MainSection01 = ({ isScrolled = false, isMobile = false }) => {
    return (
        <>
            {!isMobile && (
                <>
                    <div className="main-section-01-bg-color"></div>
                    <div className="main-section-01-bg">
                        <ContentBox >
                            <div className="main-section-01-pd">
                                <div
                                    className={`${isScrolled
                                        ? "main-section-01-logo-sticky"
                                        : "main-section-01-logo"
                                        }`}
                                >
                                    <img src={LogoImg} alt="사각 로고" className="w-full" />
                                </div>
                                <div
                                    className={`${isScrolled ? "main-section-01-logo-fake" : ""
                                        }`}
                                />
                                <div className="main-section-01-title">
                                    펜 끝에서 피어나는 나만의 글씨,
                                    <br /> 사각에서 시작하세요!
                                </div>
                                <p className="main-section-01-subtitle">
                                    사각사각, 손끝에서 느껴지는 감각적인 글씨 연습.
                                    <br /> 나만의 스타일을 찾아보세요.
                                </p>

                                {/* 다운로드 버튼들 */}
                                <StoreBtns />
                            </div>
                        </ContentBox>
                    </div>

                    <div className="main-section-01-kor-bg">
                        <ContentBox>
                            <div className="sg-tx-title-main_section_01-md sg-mb-24">
                                사각은 손글씨 연습의
                                <br /> 즐거움을 중요하게 생각합니다.
                            </div>
                            <div className="sg-tx-subtitle-md">
                                사각 앱에서는 다양한 인터랙션으로 즐겁게
                                <br /> 나만의 글씨를 완성할 수 있습니다.
                            </div>
                        </ContentBox>
                    </div>
                </>
            )}
            {isMobile && (
                <div>
                    <div className="main-section-01-bg-sm">
                        <ContentBox fullHeight>
                            <div className="main-section-01-pd-sm">
                                <div className="main-section-01-title-sm">
                                    펜 끝에서 피어나는 나만의 글씨,
                                    <br /> 사각에서 시작하세요!
                                </div>

                                {/* 다운로드 버튼들 */}
                                <StoreBtns />
                            </div>
                        </ContentBox>
                    </div>

                    <div className="main-section-01-kor-bg-sm">
                        <ContentBox>
                            <div className="flex flex-col items-center">
                                <div className="text-center sg-tx-title-main_section_01-sm  sg-mb-24">
                                    사각은 손글씨 연습의
                                    <br /> 즐거움을 중요하게 생각합니다.
                                </div>
                                <div className="text-center sg-tx-subtitle-sm">
                                    사각 앱에서는 다양한 인터랙션으로 즐겁게
                                    <br /> 나만의 글씨를 완성할 수 있습니다.
                                </div>
                                <img className="w-full" src={PencilIpadImg} alt="사각 앱 이미지" />
                            </div>
                        </ContentBox>
                    </div>
                </div>
            )}
        </>
    );
};

export default MainSection01;
